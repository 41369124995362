<template>
	<div class="email-verification">

		<img
			src="https://releasecat.io/graf/releasecat_logo.svg"
			height="40"
			alt="Releasecat"
			class="logo"
		/>
		<div class="container">
			<img 
				:src="require('../assets/images/auth/verify-email.png')"
				class="verify-email"
			>

			<p class="p">Verify your email address</p>
	
			<p class="p description">
				You've entered <span>{{ email }}</span> as the email address for your account.
				Please verify this email address by clicking the link in the email we just sent you.
			</p>
	
			<a class="button" v-if="emailProvider" :href="'https://' + emailProvider">
				Open {{ emailProvider }}
			</a>
		</div>

	</div>
</template>

<script>
export default {
	name: "AuthEmailVerification",
	data: () => ({
		email: "",
		emailProvider: "",
		allowedEmailProviders: [
			"gmail.com",
			"outlook.com",
			"protonmail.com",
			"aol.com",
			"yahoo.com",
			"hotmail.com",
			"icloud.com",
		],
	}),
	mounted() {
		if (this.$route.params && this.$route.params.userEmail) {
			this.email = this.$route.params.userEmail;
			const emailProvider = this.email.split("@")[1];
			if (this.allowedEmailProviders.includes(emailProvider)) {
				this.emailProvider = emailProvider;
			}
		}
	},
};
</script>

<style lang="sass" scoped>
@import '../assets/sass/_variables'

.email-verification
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	height: 100vh
	overflow: hidden
	background: #f7f7f7
	.logo
		margin-bottom: 30px
	.container
		width: 600px
		border: 1px solid $border-color
		padding: 30px 60px
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
		background: #fff
		display: flex
		flex-direction: column
		align-items: center
		img.verify-email
			margin: 0 auto 20px
			display: block
		.p
			font-size: 22px
			text-align: center
			margin-bottom: 10px
			line-height: 1.5em
			color: $text-black-color
			&.description
				font-size: 16px
				margin-top: 20px
				span
					font-weight: bold

		.button
			display: inline-block
			margin: 30px 0 auto
			font-size: 17px
			color: #fff
			padding: 10px 20px
			background: $accent-color
			box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 6px
			text-decoration: none
			&:hover
				// color: $accent-active-color
</style>