import { render, staticRenderFns } from "./AuthEmailVerification.vue?vue&type=template&id=729d52af&scoped=true"
import script from "./AuthEmailVerification.vue?vue&type=script&lang=js"
export * from "./AuthEmailVerification.vue?vue&type=script&lang=js"
import style0 from "./AuthEmailVerification.vue?vue&type=style&index=0&id=729d52af&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729d52af",
  null
  
)

export default component.exports